import { http, delay, HttpResponse } from 'msw';

const API_ROOT = import.meta.env.VITE_APP_API_URL;
import investments from './responses/investments.json';
import user from './responses/user.json';
import login from './responses/login.json';
import portafolios from './responses/portafolios.json';
import resume from './responses/resume.json';
import currency from './responses/currency.json';
import yuan from './responses/instruments/Q1VSLlVTRC9DTlkuSVNGLkZN.json';
import yen from './responses/instruments/Q1VSLlVTRC9KUFkuRk0=.json';
import search from './responses/instruments/search.json';
import top from './responses/instruments/top.json';
import flop from './responses/instruments/flop.json';
import topflop from './responses/instruments/topflop.json';
import movements from './responses/movements.json';
import accounts from './responses/bank-accounts/bank-accounts.json';
import currencies from './responses/bank-accounts/currencies.json';
import types from './responses/bank-accounts/types.json';
import banks from './responses/bank-accounts/banks.json';
import purchase from './responses/purchase.json';
import deudaLatam from './responses/deudaLatam.json';
import ficha from './responses/ficha.json';
import ncg380 from './responses/ncg380.json';

/**
 * rest can handle different call types:
 * get(), post(), put()
 * for more, @see https://mswjs.io/docs/api/rest
 */
const handlers = [];
const handlersAvailables = [];

/**
 * Description
 * @param {string} key - Used to set the MSW_HANDLERS array. ie: localStorage.setItem('MSW_HANDLERS', JSON.stringify(['user']))
 * @param {string} path - Api endpoint we want to mock
 * @param {string} method - get, post, put, delete
 * @param {number} status - Http status code. ie. 200, 201, 401, 403 ...
 * @param {string} body - Whatever you need to response.
 * @return {void}
 */
const createHandler = ({ key, path, method, status, body, delayTime = 0 }) => {
    handlersAvailables.push(key);
    if (localStorage.getItem('MSW_HANDLERS')?.includes(key)) {
        const handler = http[method](`${API_ROOT}${path}`, async () => {
            await delay(delayTime);

            return new HttpResponse(body, {
                status,
            });
        });
        handlers.push(handler);
    }
};

// createHandler({
//     key: 'user-403',
//     path: '/user',
//     method: 'get',
//     status: 403,
//     body: 'You shall not pass',
// });
createHandler({
    key: 'login',
    path: '/user/login',
    method: 'post',
    status: 200,
    body: JSON.stringify(login),
    // delayTime: 1000,
});
createHandler({
    key: 'user-500',
    path: '/user',
    method: 'get',
    status: 500,
    body: 'You shall not pass',
    delayTime: 1000,
});

createHandler({
    key: 'user',
    path: '/user',
    method: 'get',
    status: 200,
    body: JSON.stringify(user),
    // delayTime: 1000,
});

createHandler({
    key: 'portafolios',
    path: '/portafolios/*',
    method: 'get',
    status: 200,
    body: JSON.stringify(portafolios),
});

createHandler({
    key: 'portafolios-error',
    path: '/portafolios/*',
    method: 'get',
    status: 404,
});
createHandler({
    key: 'balance',
    path: 'user/balance/*/*',
    method: 'get',
    status: 200,
});
createHandler({
    key: 'resume',
    path: '/user/resume/*',
    method: 'get',
    status: 200,
    delayTime: 1000,
    body: JSON.stringify(resume),
});
createHandler({
    key: 'logout',
    path: '/user/logout',
    method: 'post',
    status: 201,
    delayTime: 1000,
});
createHandler({
    key: 'currency',
    path: '/termDeposit/currency',
    method: 'get',
    status: 200,
    delayTime: 1000,
    body: JSON.stringify(currency),
});
createHandler({
    key: 'search',
    path: '/instruments/search',
    method: 'get',
    status: 200,
    body: JSON.stringify(search),
});

createHandler({
    key: 'yen',
    path: '/instruments/Q1VSLlVTRC9KUFkuRk0=/historicalData?period=1D',
    method: 'get',
    status: 200,
    body: JSON.stringify(yen),
});
createHandler({
    key: 'yuan',
    path: '/instruments/Q1VSLlVTRC9DTlkuSVNGLkZN/historicalData?period=1D',
    method: 'get',
    status: 200,
    body: JSON.stringify(yuan),
});

createHandler({
    key: 'instruments-search',
    path: '/instruments/*',
    method: 'get',
    status: 200,
    body: JSON.stringify(deudaLatam),
});

createHandler({
    key: 'outofprofile',
    path: '/user/profileFinv/*',
    method: 'get',
    status: 200,
    body: true,
});
createHandler({
    key: 'outofprofile',
    path: '/user/profile/*',
    method: 'get',
    status: 200,
    body: true,
});
createHandler({
    key: 'balanceFundsCLP',
    path: '/user/balance/*/*/CLP',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        vc: 2166.6924,
        amount: 19134438,
        positions: 8831.1741,
    }),
    delayTime: 5000,
});
createHandler({
    key: 'positions',
    path: '/user/position/*/*',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        amountTot: 742.0,
        nominal: 1.0,
        averagePrice: 775,
        varPrice: -4.26,
        varPriceMoney: -33.0,
        varPriceMoneyUSD: -0.03834979662986,
        portPercent: 0.0,
        dividend: 0,
        amountTotUSD: 0.86228936664,
    }),
    delayTime: 5000,
});
createHandler({
    key: 'balanceFunds',
    path: '/user/balance/*/*/*',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        vc: 2166.6924,
        amount: Math.round(Math.random() * 100000000),
        positions: 8831.1741,
    }),
});
createHandler({
    key: 'balance',
    path: '/user/balance/*/CLP',
    method: 'get',
    status: 200,
    body: 1000000,
    delayTime: 5000,
});
createHandler({
    key: 'balanceUSD',
    path: '/user/balance/*/USD',
    method: 'get',
    status: 200,
    body: 45677,
});
createHandler({
    key: 'fundsSales',
    path: '/funds/sales',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'investmentFundsSales',
    path: '/investmentFunds/sales',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'currencySales',
    path: '/currency/sales',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'investmentFundsPurchases',
    path: '/investmentFunds/purchases',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'currencyPurchase',
    path: '/currency/purchases',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'allFundsPurchases',
    path: '/internationalFunds/purchases',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'allFundsSales',
    path: '/internationalFunds/sales',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'commission',
    path: '/investmentFunds/commission/*/*/*/*/*',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        comisionPrc: 10,
        commision: 1000,
        ivaPrc: 0.19,
        ivaAmount: 19,
    }),
});
createHandler({
    key: 'contracts',
    path: '/user/contracts',
    method: 'get',
    status: 200,
    body: null,
});
createHandler({
    key: 'funds-open',
    path: '/funds/*/open/1',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        open: true,
        overTimeEnabled: false,
        nextOpen: [2023, 10, 17, 0, 0],
        openHour: '09:30',
        closeHour: '15:55',
    }),
});
createHandler({
    key: 'investmentsfunds-open',
    path: '/investmentFunds/*/open/*/*',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        open: true,
        overTimeEnabled: false,
        nextOpen: [2023, 10, 17, 0, 0],
        openHour: '09:30',
        closeHour: '15:55',
    }),
});
createHandler({
    key: 'allfunds-open',
    path: '/internationalFunds/*/open/*/*',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        open: true,
        overTimeEnabled: false,
        nextOpen: [2023, 10, 17, 0, 0],
        openHour: '09:30',
        closeHour: '15:55',
    }),
});
createHandler({
    key: 'allfunds-balance',
    path: '/internationalFunds/balance/*/*',
    method: 'get',
    status: 200,
    body: '1000000',
});
createHandler({
    key: 'rate',
    path: '/internationalFunds/rate',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        id: '8fd8da02-b679-420e-a89a-1c844cfa6c56',
        value: 938.4,
        duration: 'PT1M30S',
        time: [16, 9, 17, 432000000],
        sourceCurrency: 'CLP',
        destinationCurrency: 'USD',
        durationLeft: 'PT1M29.657S',
    }),
});
createHandler({
    key: 'sell-allfunds',
    path: '/internationalFunds/sales',
    method: 'post',
    status: 201,
    body: JSON.stringify({
        requestDate: 1709047081.692,
        procDate: 1709047081.692,
        liqDate: 1709607600,
    }),
});
createHandler({
    key: 'sell-shares',
    path: '/shares/sales',
    method: 'post',
    status: 201,
    body: null,
});
createHandler({
    key: 'sell-ffii',
    path: '/investmentFunds/sales',
    method: 'post',
    status: 201,
    body: null,
});
createHandler({
    key: 'status',
    path: '/status',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        status: 0,
        message: 'mock status message',
        auto: false,
    }),
});
createHandler({
    key: 'top',
    path: '/shares/getTop',
    method: 'get',
    status: 200,
    body: JSON.stringify(top),
});
createHandler({
    key: 'purchasesShares',
    path: '/shares/purchases',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'purchasesFI',
    path: '/investmentFunds/purchases',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'balance-funds',
    path: '/investmentFunds/balance/*/*',
    method: 'get',
    status: 201,
    body: JSON.stringify({
        vc: 2166.6924,
        amount: Math.round(Math.random() * 100000000),
        positions: 8831.1741,
    }),
});
createHandler({
    key: 'purchasesDolar',
    path: '/currency/purchases',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'purchases',
    path: '/funds/purchases',
    method: 'post',
    status: 201,
    body: JSON.stringify(purchase),
});
createHandler({
    key: 'flop',
    path: '/shares/getFlop',
    method: 'get',
    status: 200,
    body: JSON.stringify(flop),
});
createHandler({
    key: 'topflop',
    path: '/shares/getTopFlop',
    method: 'get',
    status: 200,
    delayTime: 5000,
    body: JSON.stringify(topflop),
});
createHandler({
    key: 'shares-balance',
    path: '/shares/balance/*/*',
    method: 'get',
    status: 200,
    body: 2034,
});
createHandler({
    key: 'shares-open',
    path: '/shares/*/open/*',
    method: 'get',
    status: 200,
    body: JSON.stringify({
        open: true,
        overTimeEnabled: false,
        nextOpen: [2024, 1, 31, 0, 0],
        afterNextOpen: [2024, 2, 1, 0, 0],
        openHour: '09:30',
        closeHour: '15:55',
    }),
});
createHandler({
    key: 'errorMonitorDataTable',
    path: '/index/SU5ELk5BU0RBUTEwMC5JTkZCT0w=/constituents',
    method: 'get',
    status: 500,
});

createHandler({
    key: 'movements',
    path: '/investments/*/movements',
    method: 'get',
    body: JSON.stringify(movements),
});

createHandler({
    key: 'investments',
    path: '/investments/*',
    method: 'get',
    status: 200,
    body: JSON.stringify(investments),
});
createHandler({
    key: 'certificates',
    path: '/user/TaxCertificates',
    method: 'get',
    status: 200,
    body: JSON.stringify([
        {
            bitacoraTO: null,
            idCertificado: 'asdf1',
            rutaCertificado: '/repository/CertificadosTributarios/asdf1.pdf',
            glosaCertificado: 'Certificado de Dividendos CFI Corredora',
            rutCliente: '11.111.111-1',
        },
        {
            bitacoraTO: null,
            idCertificado: 'asdf2',
            rutaCertificado: '/repository/CertificadosTributarios/asdf2.pdf',
            glosaCertificado: 'Certificado de Dividendos Acciones Corredora',
            rutCliente: '11.111.111-1',
        },
        {
            bitacoraTO: null,
            idCertificado: 'asdf3',
            rutaCertificado: '/repository/CertificadosTributarios/asdf3.pdf',
            glosaCertificado:
                'Certificado Mayor/Menor Valor Fondo Mutuo BTG Pactual Electromovilidad USD',
            rutCliente: '11.111.111-1',
        },
    ]),
});

createHandler({
    key: 'bankAccounts',
    path: '/user/bankAccounts',
    method: 'get',
    status: 200,
    body: JSON.stringify(accounts),
});

createHandler({
    key: 'banks',
    path: '/user/bankAccounts/banks',
    method: 'get',
    status: 200,
    body: JSON.stringify(banks),
});

createHandler({
    key: 'account-types',
    path: '/user/bankAccounts/types',
    method: 'get',
    status: 200,
    body: JSON.stringify(types),
});

createHandler({
    key: 'account-currency',
    path: '/user/bankAccounts/currency',
    method: 'get',
    status: 200,
    body: JSON.stringify(currencies),
});

createHandler({
    key: 'anexoA',
    path: '/user/anexo/*/*',
    method: 'get',
    status: 200,
    body: true,
});

createHandler({
    key: 'downloadFile',
    path: '/user/contracts/download/*',
    method: 'get',
    status: 500,
});

createHandler({
    key: 'signContracts',
    path: '/user/contracts',
    method: 'post',
    status: 200,
});

createHandler({
    key: 'empty-pendingContracts',
    path: '/user/contracts',
    method: 'get',
    status: 200,
    body: JSON.stringify([]),
});

createHandler({
    key: 'loungeCase-oldMEL',
    path: '/onboardings/new',
    method: 'put',
    status: 200,
    body: 8,
});

createHandler({
    key: 'buy-DAP',
    path: '/termDeposit',
    method: 'post',
    status: 201,
});

createHandler({
    key: 'ficha',
    path: '/user/ficha',
    method: 'get',
    status: 200,
    body: JSON.stringify(ficha),
});

createHandler({
    key: 'ncg380',
    path: '/user/ncg380',
    method: 'get',
    status: 200,
    body: JSON.stringify(ncg380),
});

createHandler({
    key: 'ncg380-sign',
    path: '/user/ncg380',
    method: 'put',
    status: 200,
});

createHandler({
    key: 'custodio',
    path: '/user/safekeeping/*',
    method: 'get',
    status: 201,
    body: JSON.stringify([{ glosaCustodio: 'Glosa lorem ipsum' }]),
});

if (JSON.parse(localStorage.getItem('MSW')) === true) {
    var styleTitle = 'color: white; background: #012b36; padding: 10px;';
    var style = 'color: #839596; background: #012b36; padding: 10px;';
    console.log('%c 🧧 MSW Handlers availables.', styleTitle);
    console.log(
        `%c localStorage.setItem("MSW_HANDLERS", JSON.stringify(${JSON.stringify(
            handlersAvailables
        )}))`,
        style
    );
}
export { handlers };
